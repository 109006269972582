var widgetUrl = process.env.WIDGET_URL || 'https://assets.eddytravels.com/widget_full.min.js';
export var loadWidget = function () {
    var _a;
    window.removeEventListener('keydown', loadWidget);
    window.removeEventListener('mouseover', loadWidget);
    window.removeEventListener('touchmove', loadWidget);
    window.removeEventListener('touchstart', loadWidget);
    window.removeEventListener('scroll', loadWidget);
    if (window.__ET_LOADED) {
        return;
    }
    window.__ET_LOADED = true;
    var widget = document.createElement('script');
    widget.type = 'text/javascript';
    widget.async = true;
    widget.src = widgetUrl;
    var widgetLoader = document.getElementsByTagName('script')[0];
    (_a = widgetLoader.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(widget, widgetLoader);
};
window.addEventListener('keydown', loadWidget);
window.addEventListener('mouseover', loadWidget);
window.addEventListener('touchmove', loadWidget);
window.addEventListener('touchstart', loadWidget);
window.addEventListener('scroll', loadWidget);
